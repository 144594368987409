import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from './../../services/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  constructor(private router: Router, private authService: AuthenticationService) { }

  ngOnInit() {}

  navigateProfile() {
    this.router.navigateByUrl('/profile');
  }

  navigateHome() {
    this.router.navigateByUrl(this.loggedIn() ? '/tabs/tab1' : '/login');
  }

  loggedIn(): boolean {
    return this.authService.isAuthenticated.value;
  }

  showProfileLink(): boolean {
    return (this.loggedIn() && !this.router.isActive('/profile', true))
  }
}
